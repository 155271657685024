// best in chrome
$total: 500; // total particles
$orb-size: 150px;
$particle-size: 2px;
$time: 14s; 
$base-hue: 180; // change for diff colors (180 is nice)

.modal-open {
  padding-right: 0px !important;
}

.wrap {
  position: relative;
  top: 50%;
  left: 25%;
  width: 0; 
  height: 0; 
  transform-style: preserve-3d;
  perspective: 1000px;
  animation: rotate $time infinite linear; // rotate orb
  z-index: 0;
}

@keyframes rotate {
  100% {
    transform: rotateY(360deg) rotateX(360deg);
  }
}

.c {
  position: absolute;
  width: $particle-size;
  height: $particle-size;
  border-radius: 50%;
  opacity: 0;
  z-index: 0;
}

@for $i from 1 through $total {
  $z: (random(360) * 1deg); // random angle to rotateZ
  $y: (random(360) * 1deg); // random to rotateX
  $hue: ((40/$total * $i) + $base-hue); // set hue
  
  .c:nth-child(#{$i}){ // grab the nth particle
    animation: orbit#{$i} $time infinite;
    animation-delay: ($i * .01s); 
    background-color: hsla($hue, 100%, 50%, 1);
  }

  @keyframes orbit#{$i}{ 
    20% {
      opacity: 1; // fade in
    }
    30% {
      transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // form orb
    }
    80% {
      transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // hold orb state 30-80
      opacity: 1; // hold opacity 20-80
    }
    100% {
       transform: rotateZ(-$z) rotateY($y) translateX( ($orb-size * 3) ) rotateZ($z); // translateX * 3
    }
  }
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 100%;
	}
  100% {
		background-position: 100% 0%;
	}
}

.ragna4th-home-art {
  position: absolute;
  z-index: 100;
  left: 10vw;
}

@include media-breakpoint-up(md) {
  .ragna4th-home-art {
    left: 0vw;
  }
};

@include media-breakpoint-up(lg) {
  .ragna4th-home-art {
    left: 3vw;
  }
};


.gradient-rainbow {
	display: inline;
	background-image: radial-gradient(circle farthest-corner at 0 0, #f85d7f 25%, #e100ff 45%, #6b81fa 65%, #a0fbcd 85%);
	text-transform: capitalize;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 0px 1px dodgerblue);
  &.rainbow-2 {
    background-image: radial-gradient(circle farthest-corner at 0 0, #ff0078 12.5%, #e100ff 22.5%, #6b81fa 32.5%, #a0fbcd 42.5%, #a0fbcd 50%, #6b81fa 62.5%, #e100ff 82.5%);
    animation: animatedgradient 7s linear normal infinite;
    background-size: 700% 700%;
  }
}

.gradient-rainbow-svg {
	display: inline;
	background-image: radial-gradient(circle farthest-corner at 0 0, #f85d7f 25%, #e100ff 45%, #6b81fa 65%, #a0fbcd 85%);
	// text-transform: capitalize;
	-webkit-background-clip: content-box;
	background-clip: content-box;
  mask: url('/assets/images/rag/classes_white.svg');
  mask-repeat: no-repeat;
	-webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 0px 1px dodgerblue);
  &.rainbow-2 {
    background-image: radial-gradient(circle farthest-corner at 0 0, #ff0078 12.5%, #e100ff 22.5%, #6b81fa 32.5%, #a0fbcd 42.5%, #a0fbcd 50%, #6b81fa 62.5%, #e100ff 75.5%, #ff0078 82.5%, #e100ff 99.5%);
    animation: animatedgradient 9s linear normal infinite;
    background-size: 700% 700%;
  }
}

.Typist {
  display: inline;
}

li.active > a {
  color: #2f55d4 !important;
}

.pagination .active a {
  color: #ffffff !important;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.accordian-custom {
  border: none;
}

#mt-1 {
  margin-top: 5px !important;
}

#mb-1 {
  margin-top: 5px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.masonry-container {
  width: 100%;
  margin: auto;
}
.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
}
.tile {
  img {
    width: 100%;
  }
}

.indicator-inactive,
.indicator-active {
  height: 12px !important;
  width: 6px !important;
  border-radius: 4px;
  border: none;
  margin-left: 7px;
  margin-right: 7px;
}

.indicator-inactive {
  background-color: #2f55d4 !important;
  opacity: 0.6;
}
.indicator-active {
  background-color: #2f55d4 !important;
  transform: rotate(45deg);
}

.custom-owl-dots {
  margin-top: 10px;
  text-align: center;
}

.classForContainer {
  position: fixed;
  right: -100px;
  bottom: 30px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: rgba(47, 85, 212, 0.2);
  padding: 5px;
  align-items: center;
  border-radius: 5px !important;
  outline: none;

  &:hover {
    background-color: $primary !important;
    svg {
      stroke: $white !important;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    overflow: hidden;
    margin-top: 2px;
    margin-left: 5px;
    fill: none;
    stroke: $primary !important;
  }
}

.classForTransition {
  right: 30px;
}
//masonry

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.accordion {
  .card {
    .faq {
      > .active-link {
        color: $primary !important;
      }
    }
  }
}

.hover-border {
  .nav-link {
    &:focus,
    &.active,
    &:active {
      outline: none !important;
      border: transparent !important;
    }
    &:hover {
      border-color: transparent !important;
    }
  }
}
.custom-dots {
  .indicator-active {
    background-color: #2f55d4 !important;
  }
}
.custom-dots {
  .indicator-inactive {
    background-color: #6486e4 !important;
  }
}

.close {
  background: transparent;
  border: 0;
  font-size: 24px;
  padding: 1.35rem 1.25rem;
  color: #000 !important;
  background: transparent
    escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
    )
    center / 1em auto no-repeat;
  position: absolute !important;
  top: -7px;
  right: 20px;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
}

[class^='uil-'],
[class*=' uil-'] {
  &:before {
    margin: 0;
  }
}
.footer-border-bottom {
  border-bottom: 1px solid #283353;
}
